<template>
  <div
    class="group group/input relative flex flex-col data-[no-margin-bottom=true]:mb-0 mb-2"
    :data-disabled="isDisabled"
    :data-description="description ? true : undefined"
    :data-validation="validators && validators.length > 0 ? true : undefined"
    :data-invalid="!finalValidationResult.valid"
    :data-no-margin-bottom="undefinedIfNotTrue(noMarginBottom)"
  >
    <RequiredLabel
      v-if="!noLabel"
      :for="id"
      class="text-sm group-focus-within:text-primary-1"
      :required="required || requiredLabelMark"
      :disabled="isDisabled"
    >
      {{ label }}
    </RequiredLabel>
    <div
      ref="elementNode"
      class="flex flex-row rounded outline outline-1 outline-base-1 outline-offset-0 focus-within:text-primary-1 focus-within:outline-2 focus-within:outline-primary-1 data-[no-outline='true']:outline-1 group-data-disabled:text-neutral-4 group-data-disabled:outline-neutral-6 contrast:group-data-disabled:outline-base-1 contrast:group-data-disabled:outline-dashed group-data-invalid:bg-supporting-19 contrast:group-data-invalid:bg-c-secondary-0 group-data-description:mb-1"
      :data-no-outline="undefinedIfNotTrue(noOutline)"
    >
      <input
        :id="id"
        ref="inputElement"
        v-model="value"
        :type="type"
        class="placeholder-text-primary contrast:placeholder-text-contrast contrast:contrast-yellow w-full appearance-none rounded-l bg-transparent outline-none"
        :class="
          (!$slots.icon ? 'rounded-r' : '') +
          (type === 'color' ? ' h-10' : ' p-2')
        "
        :disabled="isDisabled"
        :required="required"
        :aria-invalid="!finalValidationResult.valid"
        :aria-errormessage="ariaErrorMessage"
        :placeholder="placeholder"
        @blur="
          () => {
            trimInput();
            initializeValidation();
            emit('blur');
          }
        "
        @focusout="emit('foucusout')"
      />
      <div
        v-if="min !== undefined || max !== undefined"
        class="mr-2 shrink-0 self-center rounded bg-neutral-9 contrast:bg-c-secondary-0 contrast:border contrast:border-base-1 p-1 text-sm text-neutral-3 shadow group-data-invalid:bottom-6 group-data-invalid:font-medium group-data-invalid:text-supporting-12"
        data-
      >
        {{ min !== undefined ? min + " / " : "" }}
        {{ value?.toString().length ?? 0 }}
        {{ max !== undefined ? " / " + max : "" }}
      </div>
      <IconPzo
        name="close"
        class="invisible contrast:group-data-invalid:visible rounded-full self-center mr-2 shrink-0 border-2 bg-base-2"
      />
      <div v-if="$slots.icon" class="mr-2 self-center">
        <slot name="icon"></slot>
      </div>
    </div>

    <div v-if="description" class="text-xs text-neutral-3">
      {{ description }}
    </div>
    <InputValidationMessage
      v-if="!validationResult.valid"
      :id="id + '_error_message'"
      :message="validationResult.message"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { undefinedIfNotTrue } from "../composables/attributes";
import { useFieldValidation } from "../composables/useFieldValidation";
import {
  required as requiredValidator,
  type ValidationFunction,
  maxCharacters,
  minCharacters,
  withValidators,
} from "../composables/validators";
import { formDisabledKey } from "../types/disabled";
import type {
  ModelValidation,
  FormValidation,
  ValidationResult,
} from "../types/formValidation";
import InputValidationMessage from "./InputValidationMessage.vue";
import RequiredLabel from "./RequiredLabel.vue";
import IconPzo from "./IconPzo.vue";

interface Props {
  id: string;
  label?: string;
  description?: string;
  type:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  modelValue?: string | number;
  disabled?: boolean;
  validators?: ValidationFunction<string | number | undefined>[];
  formValidation?: FormValidation;
  modelValidators?: ModelValidation[];
  noOutline?: boolean;
  noLabel?: boolean;
  max?: number;
  min?: number;
  required?: boolean;
  ariaLabel?: string;
  noValidationMessage?: boolean;
  noMarginBottom?: boolean;
  externalValidationResult?: ValidationResult;
  externalAriaErrorMessage?: string;
  requiredLabelMark?: boolean;
  trimInputOnBlur?: boolean;
  placeholder?: string;
}
const props = withDefaults(defineProps<Props>(), {
  trimInputOnBlur: true,
  label: undefined,
  description: undefined,
  modelValue: undefined,
  validators: undefined,
  formValidation: undefined,
  modelValidators: undefined,
  max: undefined,
  min: undefined,
  ariaLabel: undefined,
  externalValidationResult: undefined,
  externalAriaErrorMessage: undefined,
  placeholder: undefined,
});
const emit = defineEmits<{
  (e: "update:modelValue", value?: string | number): void;
  (e: "foucusout"): void;
  (e: "blur"): void;
}>();

const formDisabled = inject(formDisabledKey, false);
const isDisabled = computed(() => {
  return formDisabled || props.disabled;
});

const elementNode = ref<HTMLElement>();
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const allValidators = computed(() => {
  if (isDisabled.value) {
    return [];
  }
  return withValidators(props.validators ?? [], [
    [requiredValidator, props.required === true],
    [maxCharacters(props.max), props.max !== undefined],
    [minCharacters(props.min), props.min !== undefined],
  ]);
});

const finalValidationResult = computed(() => {
  return props.externalValidationResult ?? validationResult.value;
});

const { validationResult, initializeValidation } = useFieldValidation(
  props.id,
  value,
  allValidators,
  props.modelValidators,
  props.formValidation,
);

const ariaErrorMessage = computed(() => {
  if (props.externalAriaErrorMessage) {
    return props.externalAriaErrorMessage;
  }
  return !finalValidationResult.value.valid
    ? props.id + "_error_message"
    : undefined;
});

const inputElement = ref<HTMLElement>();

function focus() {
  inputElement.value?.focus();
}

function trimInput() {
  if (props.trimInputOnBlur && value.value && typeof value.value === "string") {
    value.value = value.value.trim();
  }
}

defineExpose({ focus, elementNode, inputElement });
</script>
